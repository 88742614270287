import request from "@/utils/request"

// CA 电子签名
export default {
	// 获取免密签名图片 云医签  儿童
	getSignImg: data =>
		request("/yyq-ca/push/sign", { method: "post", body: data }),
	// 获取有密码的用户签名图片 云医签  儿童
	getSignPassImg: data =>
		request("/yyq-ca/push/sign/user-confirm", { method: "get", params: data }),
	// 获取免密签名图片 掌信签
	getSZSignImg: data =>
		request("/zxq-ca/push/sign", { method: "post", body: data }),
	// 获取有密码的用户签名图片 掌信签
	getSZSignPassImg: data =>
		request("/zxq-ca/push/sign/user-confirm", {
			method: "get",
			params: data
		})
}
