<template>
	<Modal
		class="add-pro-modal"
		:value="visible"
		:fullscreen="fullscreen"
		:mask-closable="false"
		:styles="{ top: fullscreen ? 0 : '20px', bottom: 0 }"
		@on-cancel="onCancel"
		width="1150"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="add-pro-modal-header">
			<h2>个人签名</h2>
			<a href="javascript:;" class="fullscreen" @click="handleFullScreen">
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<Signature :signHeight="signHeight" v-if="visible"></Signature>
		<div slot="footer">
			<Button @click="onCancel" type="primary">关闭</Button>
		</div>
	</Modal>
</template>
<script>
import Signature from "@/views/Personal/Signature/Index.vue"
import { mapState } from "vuex"

export default {
	props: ["visible"],
	data() {
		return {
			loading: false,
			fullscreen: false // 是否全屏弹出框
		}
	},
	components: {
		Signature
	},
	computed: {
		...mapState({
			conHeight: state => state.contentHeight - 230
		}),
		signHeight() {
			return this.fullscreen ? this.conHeight : this.conHeight - 30
		}
	},
	methods: {
		handleFullScreen() {
			// 全屏查看
			this.fullscreen = !this.fullscreen
		},
		onCancel() {
			this.$emit("onCancel")
			this.fullscreen = false
		}
	}
}
</script>
